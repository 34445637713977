import { render, staticRenderFns } from "./CompanyApplications.vue?vue&type=template&id=dde6855a&scoped=true"
import script from "./CompanyApplications.vue?vue&type=script&lang=js"
export * from "./CompanyApplications.vue?vue&type=script&lang=js"
import style0 from "./CompanyApplications.vue?vue&type=style&index=0&id=dde6855a&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dde6855a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/src/AgendasLtd.TravioPro.Vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dde6855a')) {
      api.createRecord('dde6855a', component.options)
    } else {
      api.reload('dde6855a', component.options)
    }
    module.hot.accept("./CompanyApplications.vue?vue&type=template&id=dde6855a&scoped=true", function () {
      api.rerender('dde6855a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/host-admin-crm/CompanyApplications.vue"
export default component.exports