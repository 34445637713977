var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row" },
    [
      _c(
        "div",
        { staticClass: "vx-col w-full mb-base" },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col xs:w-full lg:w-3/4" }, [
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center" },
                [
                  _c(
                    "vs-button",
                    { staticClass: "mr-2 sm:mb-0", on: { click: _vm.addUser } },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "vx-col xs:w-full lg:w-1/4" },
              [
                _c("vs-input", {
                  staticClass:
                    "float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2",
                  attrs: { placeholder: "Search..." },
                  on: { input: _vm.updateSearchQuery },
                  model: {
                    value: _vm.searchQuery,
                    callback: function ($$v) {
                      _vm.searchQuery = $$v
                    },
                    expression: "searchQuery",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.usersData
            ? _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass:
                  "ag-theme-material w-100 my-4 dynamic-height-user-grid ag-grid-table",
                attrs: {
                  components: _vm.cellRendererComponents,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.usersData,
                  gridOptions: _vm.gridOptions,
                  rowSelection: "single",
                  pagination: true,
                  paginationPageSize: _vm.paginationPageSize,
                  suppressPaginationPanel: true,
                },
                on: { "selection-changed": _vm.onSelectionChanged },
              })
            : _vm._e(),
          _c("vs-pagination", {
            attrs: { total: _vm.totalPages, max: 5 },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Bulk Insert Results",
            "buttons-hidden": "",
            active: _vm.bulkInsert.showBulkInsertPrompt,
          },
          on: {
            cancel: _vm.clearBulkInsertPrompt,
            close: _vm.clearBulkInsertPrompt,
            "update:active": function ($event) {
              return _vm.$set(_vm.bulkInsert, "showBulkInsertPrompt", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        "Successfully added " +
                          _vm.bulkInsert.successfulInserts +
                          " user(s)."
                      )
                    ),
                  ]),
                ]),
                _vm.bulkInsert.missingRoleCount
                  ? _c("div", { staticClass: "vx-col w-full mt-4" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            "There are " +
                              _vm.bulkInsert.missingRoleCount +
                              " user(s) with unspecified roles, select a role from the drop-down to set roles for all these users."
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm.bulkInsert.missingRoleCount
                ? _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-6",
                      attrs: { color: "danger", type: "flat" },
                      on: { click: _vm.clearBulkInsertPrompt },
                    },
                    [_vm._v("Cancel")]
                  )
                : _vm._e(),
              _vm.bulkInsert.missingRoleCount
                ? _c(
                    "vs-button",
                    {
                      staticClass: "float-right ml-auto mt-6",
                      on: { click: _vm.fillInMissingRoles },
                    },
                    [_vm._v("Fill in missing roles")]
                  )
                : _vm._e(),
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto mt-6",
                  on: { click: _vm.clearBulkInsertPrompt },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }